import { Box, Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import { DashboardState } from "../models/dashboard.state";

type HistoryProps = {
	data: Array<DashboardState>,
	title: string
}

export default function History(props: HistoryProps): JSX.Element {
	const { data, title } = props;
	return (
		<Paper elevation={0}>
			<Box p={5}>
				<Typography variant="h6">{title}</Typography>
				<Grid container direction="column">
					{ data.map((el: DashboardState, index: number) => (
						<Grid item container direction="row" justify="space-between" key={el.title + index}>
							<Typography variant="subtitle1">{el.title}</Typography>
							<Typography variant="subtitle1">{el.value}</Typography>
						</Grid>
					))}
				</Grid>
			</Box>
		</Paper>
	);
}