import { debounce } from "lodash";
import React from "react";
import { UserInput } from "../models/input.state";
import { FetcherData } from "../models/models/util.model";
import { clearToken, decryptLinkUrl, getToken, saveToken, saveUser } from "../utils/auth";
import { fetcher } from "../utils/fetcher";
import { changePasswordURL, checkPrice, loginURL, purchaseURL, registerURL } from "../lib/urls";
import { validation } from "../utils/validation";
import { reducerErrorField, reducerUserInput } from "./reducer";
import { User } from "../models/models/user.model";
import { CountryModel } from "../models/models/country.model";
import { useHistory } from "react-router-dom";
import { FinishPurchase } from "../models/models/purchase.model";

// @ts-ignore
import { useSnackbar } from "react-simple-snackbar";
import { ActionInfoPage } from "../models/info_page.state";

// Statefields
export default(setInfoPage: React.Dispatch<ActionInfoPage>, setResponValue? :React.Dispatch<React.SetStateAction<FinishPurchase>>, responValue?: FinishPurchase) => {
	const [values, dispatchValue] = React.useReducer(reducerUserInput, {
		name: "",
		phone: "",
		codePhone: "ID",
		email: "",
		password: "",
		refferal_code: "",
		isHidden: true,
		whichActive: "whichActive",
		"amount purchase": "100",
		currency: "eth",
		eth: "",
		doge: "",
		agree: false,
		address: "",
		isDone: false,
		isSubmit: false,
		isModalOpening: false,
		"old password": ""
	});

	const [errorField, setErrorField] = React.useReducer(reducerErrorField, {
		name: false,
		refferal_code: false,
		codePhone: false,
		email: false,
		password: false,
		phone: false,
		isHidden: false,
		whichActive: false,
		"amount purchase": false,
		currency: false,
		"eth": false,
		"doge": false,
		agree: false,
		address: false,
		isDone: false,
		isSubmit: false,
		isModalOpening: false,
		"old password": false
	});

	const [openSnackbar, closeSnackbar] = useSnackbar();

	const history = useHistory();

	// effect
	React.useEffect(() => {
		_validationInput(values.whichActive, values);
	}, [values]);
	
	React.useEffect(() => {
		const refferal = decryptLinkUrl(history.location.search.slice(1)).slice(9);
		dispatchValue({ type: "refferal_code", value: refferal });
	}, []);

	React.useEffect(() => {
		if (values.isModalOpening) {
			_getTotalCoin(values["amount purchase"], values.currency);
		}
	}, [values.currency, values["amount purchase"], values.isModalOpening]);

	// methods
	const _getTotalCoin = React.useCallback(debounce(async (coinPurchase: string, typeCoin: string) => {
		try {
			const senData = {
				nominal: coinPurchase,
				coin: typeCoin.toUpperCase()
			};
			dispatchValue({ type: "eth", value: "" });
			dispatchValue({ type: "doge", value: "" });

			if (Number(coinPurchase) < 100) {
				// setErrorField({ type: "amount purchase", value: true });
				setErrorField({ type: "amount purchase", value: true });
				throw Error("Minimum Purchase is $ 100!");
			}
			
			const response = await fetcher("POST", checkPrice, senData);

			if (response.status === 500) {
				throw Error("Please only input number or try again!");
			}

			if (response.status === 401) {
				clearToken(getToken());
				throw Error("Please re-login!");
			}
			
			const data: FetcherData = await response.json();
			if (response.status === 200) {
				const value = data.data as number;
				
				dispatchValue({ type: "eth", value: value });
				dispatchValue({ type: "doge", value: Math.ceil(value) });
			}
		} catch (error) {
			setInfoPage({ type: "errorMessage", value: error.message ?? "Something went wrong!" });
			setInfoPage({ type: "hasError", value: true });
		} finally {
			setInfoPage({ type: "hasError", value: false });
		}
	}, 1000), []);

	const _validationInput = React.useCallback(debounce(async (attr: keyof UserInput, values: UserInput) => {
		let data = values[attr];

		if (attr === "phone") {
			if (!values.codePhone) {
				setErrorField({ type: "phone", value: true });
				setInfoPage({ type: "loading", value: false });
				openSnackbar("Select phone code!");

				return;
			}

			data = `${values.phone}&countryCode=${values.codePhone}`;
		}

		if (attr === "email") {
			data = `${values.email}`;
		}

		if (attr === "codePhone") {
			return;
		}

		const isError = await validation(attr, data as string | boolean | CountryModel);
		setErrorField({ type: attr, value: isError ?? true });
	}, 1000), []);

	const useShowPassword = () => {
		dispatchValue({ type: "isHidden", value: !values.isHidden });
	};
	
	const useHandleChange = (attr: keyof UserInput) => (e: React.ChangeEvent<HTMLInputElement | { value: unknown }>) => {
		if (attr === "agree") {
			const ev = e as React.ChangeEvent<HTMLInputElement>;
			dispatchValue({ type: attr, value: ev.target.checked });
		} else {
			dispatchValue({ type: attr, value: e.target.value });
		}
		dispatchValue({ type: "whichActive", value: attr });
		// _validationInput(attr, values);
	};

	const useHandleSubmit = (type: string) => async (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
		try {
			e.preventDefault();
			setInfoPage({ type: "loading", value: true });
			
			let url = type === "login" ? loginURL : registerURL;

			// PURCHASE FUnction
			if (type === "Purchase Now") {
				url = purchaseURL;
				dispatchValue({ type: "isSubmit", value: true });

				if (values.isSubmit) {
					dispatchValue({ type: "isSubmit", value: false });
					dispatchValue({ type: "isDone", value: false });

					return;
				}

				const data = {
					nominal: values["amount purchase"],
					coin: values.currency.toUpperCase(),
					coin_value: values.eth ?? values.doge
				};
				
				const response: Response = await fetcher("POST", url, data);
				if (response.status === 500) {
					throw Error("Something went wrong, please try again!");
				}
	
				if (response.status === 401) {
					clearToken(getToken());
					throw Error("Please re-login!");
				}

				const result: FetcherData = await response.json();

				const { address, status_url, qr_url, nominal, coin, checkout_url } = result.data as FinishPurchase;
				setResponValue!({ ...responValue!, checkout_url, address, status_url, qr_url, nominal, coin });
				dispatchValue({ type: "isDone", value: true });
				
				return;
			}

			// Change password
			if (type === "Change Password") {
				url = changePasswordURL;
				dispatchValue({ type: "isSubmit", value: true });

				const data = {
					password_lama: values["old password"],
					password_baru: values.password
				};

				const response: Response = await fetcher("POST", url, data);
				if (response.status === 500) {
					throw Error("Something went wrong, please try again!");
				}

				if (response.status === 401) {
					clearToken(getToken());
					throw Error("Please re-login!");
				}

				const result: FetcherData = await response.json();
				dispatchValue({ type: "isSubmit", value: false });

				const { message } = result;

				setInfoPage({ type: "hasError", value: true });
				setInfoPage({ type: "errorMessage", value: message });

				return;
			}

			// Login function
			const data = {
				username: values.email,
				email: values.email,
				password: values.password,
				name: values.name,
				codePhone: values.codePhone,
				phone: `(${values.codePhone}) ${values.phone}`,
				referal_by: values.refferal_code
			};
		
			Object.entries(errorField).forEach(([key, val]) => {
				if (val) {
					throw new Error(`${key.toUpperCase()} is not valid!`);
				}
			});

			const response: Response = await fetcher("POST", url, data);

			if (response.status === 500) {
				throw Error("Something went wrong, please try again!");
			}

			if (response.status === 401) {
				clearToken(getToken());
				throw Error("Please re-login!");
			}

			const result: FetcherData = await response.json();
		
			// block for Register function
			if (url === loginURL || registerURL) {
				if (response.status === 201) {
					if (result.message === "user created") {
						// then login
						const responLogin: Response = await fetcher("POST", loginURL, data);

						if (responLogin.status === 500) {
							throw Error("Something went wrong, please try again!");
						}
			
						if (responLogin.status === 401) {
							clearToken(getToken());
							throw Error("Please re-login!");
						}
						const resultLogin: FetcherData = await responLogin.json();
						const { token, created, email, name, phone, referal_by, referal_code } = resultLogin.data as User;
						saveToken(token!);
						saveUser({
							created,
							email,
							name,
							phone,
							referal_by,
							referal_code
						});

						setInfoPage({ type: "hasError", value: true });
						setInfoPage({ type: "errorMessage", value: "Success Login!" });
						history.push("/dashboard");
					}
					return;
				}

				if (response.status === 200) {
					const { token, created, email, name, phone, referal_by, referal_code } = result.data as User;
					saveUser({
						created,
						email,
						name,
						phone,
						referal_by,
						referal_code
					});
					saveToken(token!);

					setInfoPage({ type: "hasError", value: true });
					setInfoPage({ type: "errorMessage", value: "Success Login!" });
					openSnackbar("Success Login");
					history.push("/dashboard");
					return;
				} else {
					setInfoPage({ type: "errorMessage", value: result.message });
					setInfoPage({ type: "hasError", value: true });
				}
			}

			return;
		} catch (error) {
			setInfoPage({ type: "loading", value: false });
			setInfoPage({ type: "hasError", value: true });
			setInfoPage({ type: "errorMessage", value: error.message ?? "Something went wrong!" });
		} finally {
			setInfoPage({ type: "loading", value: false });
			setInfoPage({ type: "hasError", value: false });
			setInfoPage({ type: "errorMessage", value: "" });
		}
	};

	return { useShowPassword, useHandleChange, values, useHandleSubmit, errorField, dispatchValue };
};
