import { Box, Grid, Paper, Typography } from "@material-ui/core";
import { DragHandle } from "@material-ui/icons";
import React from "react";

type PaperPanelProps = {
	title: string,
	value: number,
	icon: JSX.Element | undefined
}

export default function PaperPanel(props: PaperPanelProps): JSX.Element {
	const { title, value, icon } = props;

	return (
		<Paper elevation={0}>
			<Box py={4} px={3}>
				<Grid container direction="row" alignItems="center">
					<Grid item xs={8} container direction="column" >
						<Grid item>
							<Typography variant="h6">${value}</Typography>
						</Grid>
						<Grid item>
							<Typography variant="subtitle1">{title}</Typography>
						</Grid>
					</Grid>
					<Grid item container xs={4} justify="flex-end">
						{icon}
					</Grid>
				</Grid>
			</Box>
		</Paper>
	);
}