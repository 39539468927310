import { Paper } from "@material-ui/core";
import { fill } from "lodash";
import React from "react";
import { VictoryArea, VictoryAxis, VictoryBar, VictoryChart, VictoryLabel, VictoryLine, VictoryScatter, VictoryTheme, VictoryTooltip, VictoryVoronoiContainer } from "victory";
import { LoadingHooks } from "../hooks";
import { graphROEURL } from "../lib/urls";
import { GraphROE } from "../models/models/bonus.model";
import { FetcherData } from "../models/models/util.model";
import { fetcher } from "../utils/fetcher";

interface IDataItem {
  x: number,
	y: number | null,
	date: string;
}

// @ts-nocheck
class CatPoint extends React.Component {
	render() {
		// @ts-ignore
		const { x, y, datum } = this.props; // VictoryScatter supplies x, y and datum
		// @ts-ignore
		const cat = datum._y <= 0.5 ? "😢" : "🥰";
		return (
			<text x={x} y={y} fontSize={10}>
				{cat}
			</text>
		);
	}
}

const chartDatas: IDataItem[] = [
	// { x: 1, y: 0.5 },
	// { x: 2, y: 0.45 },
	// { x: 3, y: 0.51 },
	// { x: 4, y: 0.41 },
	// { x: 5, y: 0.5 },
	// { x: 6, y: 0.75 },
	// { x: 7, y: 0.69 },
	// { x: 8, y: 0.4 },
	// { x: 9, y: 0.55 },
	// { x: 10, y: 0.62 },
	// { x: 11, y: 0.45 },
	// { x: 12, y: 0.45 },
	// { x: 13, y: 0.75 },
	// { x: 14, y: 0.65 },
	// { x: 15, y: 0.8 },
	// { x: 16, y: 0.7 },
	// { x: 17, y: 0.62 },
	// { x: 18, y: 0.51 },
	// { x: 19, y: 0.63 },
	// { x: 20, y: 0.78 },
];


export default function LineChart(): JSX.Element {
	const [chartData, setChartData] = React.useState<IDataItem[]>([]);
	const { infoPage, setInfoPage } = LoadingHooks();

	const getData = async () => {
		try {
			const response = await fetcher("GET", graphROEURL);
	
			const result: FetcherData = await response.json();
			const data: GraphROE[] = result.data as GraphROE[];
	
			const graph: IDataItem[] = [];
			let no: number = 1;
			for (const i of data) {
				graph.push({ x: no, y: i.bonus_roe, date: i.tanggal });
				no++;
			}
	
			setChartData(graph);
		} catch (error) {
			setInfoPage({ type: "errorMessage", value: error.message ?? "Something went wrong!" });
			setInfoPage({ type: "hasError", value: true });
		}
	};

	React.useEffect(() => {
		if (infoPage.loading) return;
		getData();
	}, [infoPage.loading]);

	return (
		<Paper elevation={0}>
			<VictoryChart 
				maxDomain={{ y: 0.8 }}
				minDomain={{ y: 0, x: 0 }}
				theme={VictoryTheme.material}
				// domainPadding={{ x: 20 }}
				
				containerComponent={
					<VictoryVoronoiContainer />
				}
			>
				<VictoryLabel text="profit" x={20} y={20} backgroundStyle={{ fill: "white" }} backgroundPadding={3}/>
				<VictoryLabel text="days" x={310} y={320} backgroundStyle={{ fill: "white" }} backgroundPadding={3}/>

				<VictoryAxis 
					tickFormat={(x) => x}
				/>

				<VictoryAxis
					dependentAxis
					tickFormat={(x) => (`${x}`)}
				/>

				<VictoryBar
					barRatio={0.75}
					// barWidth={5}
					style={{
						// data: { stroke: "#c43a31", fill: "green" },
						// parent: { border: "1px solid #ccc" },
						
						data: {
							// fill: "green"
							fill: ({ datum }) => datum.y > 0.7 ? "#66BB6A" : datum.y > 0.6 ? "#CDDC39" : datum.y > 0.5 ? "#FFEB3B" : datum.y > 0.45 ? "#FF9800" : "#F44336",
							// stroke: ({ datum }) => datum.y > 1  ? "white" : "black",
							// fillOpacity: 0.7,
							strokeWidth: 1,
							stroke: "black"
							// width:
							// width: "5rem"
						},
					}}
					data={chartData}
					labels={(x: any) => `Date ${x.datum.date}: ${x.datum.y}`}
					labelComponent={ <VictoryTooltip /> }
				/>

				{/* <VictoryScatter
					dataComponent={<CatPoint/>}
					// y={(d) => Math.sin(2 * Math.PI * d.x)}
					// samples={15}
					data={chartData}
				/> */}

			</VictoryChart>
		</Paper>
	);
}