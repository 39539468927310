import React from "react";
import { Box, Button, Container, Grid, Paper, Typography, Link as LinkMui } from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { Loading, MyFormComponent, Notification } from "../components";
import { UserInput } from "../models/input.state";

// custom hooks
import { LoadingHooks, UserInputHooks } from "../hooks";
import { Link, useHistory } from "react-router-dom";
import { getToken } from "../utils/auth";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			width: "100vw",
			height: "100vh"
		},
		paper: {
			padding: theme.spacing(4),
			width: theme.spacing(50)
		},
		form: {
			display: "flex",
			flexDirection: "column",
			margin: theme.spacing(2),
		},
		fullWidth: {
			width: "100%"
		},
		bg: {
			backgroundColor: theme.palette.secondary.main
		},
		loginButton: {
			backgroundColor: theme.palette.primary.main,
			"&:hover": {
				backgroundColor: "#D2910F"
			}
		}
	}),
);

export default function LoginPages(): JSX.Element {
	const classes = useStyles();
	const history = useHistory();

	// Textfields
	const labels: Array<keyof UserInput> = ["email", "password"];

	// hooks
	const { setInfoPage, infoPage } = LoadingHooks();
	const { useShowPassword, useHandleChange, useHandleSubmit, values, errorField } = UserInputHooks(setInfoPage);

	React.useEffect(() => {
		getToken() && history.push("/dashboard");
	}, []);

	return(
		<div className={classes.bg}>
			<Loading open={infoPage.loading} />

			<Container className={classes.root}>
				<Paper className={classes.paper} elevation={5}>
					<Grid container direction="column" alignItems="center" justify="center">
						<Grid item xs>
							<img src={process.env.PUBLIC_URL + "/assets/img/logo-png.png"} />
						</Grid>
						<Box mt={2}>
							<Typography variant="h4">Login</Typography>
						</Box>	
						<Grid item xs className={classes.fullWidth}>
							<form className={classes.form} onSubmit={useHandleSubmit("login")} autoComplete="on">
								{labels.map((val) => (
									<Box mt={3} key={val} className={classes.fullWidth}>
										<MyFormComponent 
											el={val} 
											onChange={useHandleChange} 
											onClick={useShowPassword} 
											values={values} key={val} 
											errorField={errorField}
										/>
									</Box>
								))}
								<Box pt={5}>
									<Grid container direction="column" alignItems="center" spacing={2}>
										<Grid item className={classes.fullWidth}>
											<Button className={classes.loginButton} type="submit" fullWidth variant="contained">Login</Button>
										</Grid>
										<Grid item xs>
											<Typography variant="subtitle1" >
												Don&apos;t have account yet? <Link to="register"><LinkMui color="primary">Join here!</LinkMui></Link>
											</Typography> 
										</Grid>
									</Grid>
								</Box>
							</form>
						</Grid>
					</Grid>
				</Paper>
			</Container>
		</div>
	);
}