import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import React from "react";

type MyModalProps = {
	isOpen: boolean,
	title: string,
	contents: JSX.Element,
	actions: JSX.Element,
	onClose: () => void
}

export default function MyModal (props: MyModalProps) {
	const { isOpen, onClose, title, actions, contents } = props;
	return (
		<Dialog open={isOpen} onClose={onClose} fullWidth>
			<DialogTitle id={title}>{title}</DialogTitle>
			<DialogContent dividers>
				{contents}
			</DialogContent>
			<DialogActions>
				{actions}
			</DialogActions>
		</Dialog>
	);
}