import React from "react";
import { FinishPurchase } from "../models/models/purchase.model";

export default () => {
	const [responValue, setResponValue] = React.useState<FinishPurchase>({
		address: "",
		checkout_url: "",
		coin: "",
		nominal: "",
		qr_url: "",
		status_url: ""
	});

	return { responValue, setResponValue };
};