import { Avatar, Checkbox, Collapse, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, Radio, RadioGroup, TextField, Typography } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import React from "react";
import ReactInputMask from "react-input-mask";
import { CountryModel } from "../../models/models/country.model";
import { ErrorUserInput, UserInput } from "../../models/input.state";
import { fetcher } from "../../utils/fetcher";
import { countryURL } from "../../lib/urls";
import { decryptLinkUrl } from "../../utils/auth";
import { useHistory } from "react-router-dom";

type MyFormComponentProps = {
	values: UserInput,
	el: keyof UserInput | keyof ErrorUserInput,
	errorField: ErrorUserInput,
	onChange: (attr: keyof UserInput) => (e: React.ChangeEvent<HTMLInputElement | { value: unknown }>) => void,
	onClick: (event: React.MouseEvent<HTMLButtonElement>) => void,
}

export default function MyFormComponent(props: MyFormComponentProps) {
	const { el, values, onChange, onClick, errorField } = props;
	const [autoComplete, setAutoComplete] = React.useState<string>("");
	const [helper, setHelper] = React.useState<string>("");
	const [country, setCountry] = React.useState<Array<CountryModel>>([]);
	const [prefix, setPrefix] = React.useState<JSX.Element>();
	const [reff, setReff] = React.useState<string>("");
	const [open, setOpen] = React.useState<boolean>(false);

	const history = useHistory();

	const getCountryData = async () => {
		const response = await fetcher("GET", countryURL);
		const result: CountryModel[] = await response.json();
		setCountry(result);
	};

	React.useEffect(() => {
		const refferal = decryptLinkUrl(history.location.search.slice(1)).slice(9);
		setReff(refferal);
	}, []);

	const reRender = () => {
		setPrefix(
			<>
				<TextField
					id="country-code-select"
					onChange={onChange("codePhone")}
					fullWidth
					variant="outlined"
					label={"country code"}
					select
				>
					{country.map((val: CountryModel) => (
						<MenuItem value={val.alpha2Code} key={val.alpha2Code} >
							<Grid container direction="row" spacing={2} wrap="nowrap">
								<Grid item>
									<Avatar alt={val.name} src={val.flag} style={{ height: "18px", width: "18px" }}/>
								</Grid>
								<Grid item>{val.alpha2Code} +{val.callingCodes}</Grid>
							</Grid>
						</MenuItem>
					))}
				</TextField>
			</>
		);
	};

	React.useEffect(() => {
		if (el === "password") {
			setAutoComplete("new-password");
			setHelper("password at least 8 characters, must have capital letter, symbol, number!");
		}

		if (el === "name") {
			setAutoComplete("name");
			setHelper("name at least 4 characters");
		}

		if (el === "email") {
			setAutoComplete("email");
			setHelper("incorrect email address!");
		}

		if (el === "phone") {
			setHelper("incorrect phone number! please re input your number");
			getCountryData();
			reRender();
		}

		if (el === "refferal_code") {
			setHelper("incorrect referral code!");
		}

		if (el === "amount purchase") {
			setHelper("Minimum Purchase $100! Number Only!");
		}

	}, []);

	React.useEffect(() => {
		reRender();
	}, [country]);

	// const agreemeen = (ev: React.ChangeEvent<HTMLInputElement>) => {
	// 	onChange(ev);
	// 	setOpen(!open);
	// };

	return(
		<FormControl key={el} fullWidth>
			{el === "phone" ?
				<Grid container 
					direction="row" 
					spacing={2} 
					alignItems={errorField[el] ? "center" : "flex-end"} 
				>
					<Grid item container spacing={2}>
						<Grid item xs={12} lg={4}>
							{prefix}
						</Grid>
						<Grid item xs={12} lg={8}>
							{/* <ReactInputMask
								mask={"999-9999-9999"}
								value={values[el]}
								onChange={onChange(el)}
								disabled={false}
							> */}
							{/* {() => ( */}
							<TextField 
								value={values[el]}
								onChange={onChange(el)}
								error={errorField[el]}
								type="text"
								helperText={errorField[el] && helper}
								variant="outlined"
								fullWidth
								label={el}
							/>
							{/* )}
							</ReactInputMask> */}
						</Grid>
					</Grid>
				</Grid>
				: el === "amount purchase" ?
					<Grid>
						{/* <ReactInputMask
							mask={values[el].length >= 6 ? "9,999.99" : "999.99"}
							value={values[el]}
							// maskChar=" "
							onChange={onChange(el)}
							disabled={false}
						>{() => (
								<TextField
									error={errorField[el]}
									type="text"
									helperText={errorField[el] && helper}
									variant="outlined"
									fullWidth
									label={el}
									InputProps={{
										startAdornment: <InputAdornment position="start" color="primary" >$</InputAdornment>
									}}
								/>)}
						</ReactInputMask> */}
						<TextField
							error={errorField[el]}
							value={values[el]}
							onChange={onChange(el)}
							type="text"
							helperText={errorField[el] && helper}
							variant="outlined"
							fullWidth
							label={el}
							InputProps={{
								startAdornment: <InputAdornment position="start" color="primary" >$</InputAdornment>
							}}
						/>
					</Grid>
					: el === "currency" ?
						<RadioGroup
							name="currency"
							value={values[el]}
							onChange={onChange(el)}
						>
							<Grid container direction="row" justify="space-around">
								<FormControlLabel
									control={<Radio color="primary" />}
									value="eth"
									color="primary"			
									label={
										<>
											<img src={process.env.PUBLIC_URL + "/assets/img/logos_ethereum.png"} />
											<Typography variant="subtitle1">ETH</Typography>
										</>
									}
								/>
								<FormControlLabel
									control={<Radio color="primary" />}
									value="doge"
									color="primary"				
									label={
										<>
											<img src={process.env.PUBLIC_URL + "/assets/img/dogecoin_1.png"} />
											<Typography variant="subtitle1">DOGE</Typography>
										</>
									}
								/>
							</Grid>
						</RadioGroup>
						: el === "agree" ?
							<Grid>
								<FormControlLabel
									control={
										<Checkbox
											checked={values[el]}
											onChange={onChange(el)}
											color="primary"
											name="agree"
										/>
									}
									label="Agree to tos"
								/>
								<Collapse in={values[el]} timeout="auto" unmountOnExit>
									<Typography variant="h6">Term of services</Typography>
									<Typography variant="subtitle1">
										Saya memahami konsep bisnis bagi hasil vccom.trade community bahwa:<br />
											1. Bagi hasil syariah 0.4% - 0.8% adalah modal dan profit hingga 300%. <br />
											2. Real bisnis di arbitrage cryptocurrency trade. <br />
											3. Paket purchase yang anda pilih sudah termasuk blockchain fee sebesar 1%
											dan saya siap dengan segala konsekuensinya.
									</Typography>
								</Collapse>
							</Grid>
							:
							<Grid>
								<TextField
									error={errorField[el]}
									id={el}
									type={(el === "password" || el === "old password") ? values.isHidden ? "password" : "text" : ""}
									value={el === "refferal_code" && reff.length > 1 ? reff : values[el]}
									onChange={onChange(el)}
									autoComplete={autoComplete}
									helperText={errorField[el] && helper}
									label={el}
									variant="outlined"
									name={el}
									fullWidth
									style={{
										color: el === "eth" || el === "doge" ? "white" : ""
									}}
									disabled={el === "eth" || el === "doge"}
									InputProps={{
										endAdornment: (el === "password" || el === "old password") &&
								<IconButton
									aria-label="toggle password visibility"
									onClick={onClick}
								>
									{values.isHidden ? <Visibility color="primary"/> : <VisibilityOff color="primary" />}
								</IconButton>
									}}
								/>
							</Grid>
			}
		</FormControl>
	);
}