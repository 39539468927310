import React from "react";

export default() => {
	const [open, setOpen] = React.useState<{[string: string]: boolean}>({});

	const showCollapse = (attr: string) => () => {
		setOpen({ ...open, [attr]: !open[attr] });
	};

	return { showCollapse, open };
};