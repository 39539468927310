import { createMuiTheme } from "@material-ui/core";

const _myTheme = createMuiTheme({
	palette: {
		primary: {
			main: "#EFA91B",
			contrastText: "#FFFFFF",
			dark: "#2C2C4E"
		},
		secondary: {
			main: "#1A1A2E"			
		},
		text: {
			primary: "#FFFFFF"
		}
	},
	typography: {
		"fontFamily": "\"Inter\", \"Helvetica\", \"Arial\", sans-serif",
		"fontWeightRegular": 400,
		"fontWeightMedium": 500,
		"fontWeightBold": 700
	},
	overrides: {
	}
});

const defaultTheme = _myTheme;
const { breakpoints } = _myTheme;

const myTheme = {
	...defaultTheme,
	overrides: {
		...defaultTheme.overrides,
		MuiTypography: {
			h1: {
				[breakpoints.down("xs")]: {
					fontSize: "2.3rem"
				}
			},
			h2: {
				[breakpoints.down("xs")]: {
					fontSize: "2rem"
				}
			},
			h3: {
				[breakpoints.down("xs")]: {
					fontSize: "1.8rem"
				}
			},
			h4: {
				[breakpoints.down("xs")]: {
					fontSize: "1.7rem"
				}
			},
			h5: {
				[breakpoints.down("xs")]: {
					fontSize: "1.6rem"
				}
			},
			h6: {
				[breakpoints.down("xs")]: {
					fontSize: "1rem"
				}
			},
			body1: {
				[breakpoints.down("xs")]: {
					fontSize: "1rem"
				}
			},
			body2: {
				[breakpoints.down("xs")]: {
					fontSize: "0.9rem"
				}
			},
			subtitle1: {
				fontSize: "1rem",
				[breakpoints.down("xs")]: {
					fontSize: "0.8rem"
				}
			},
			subtitle2: {
				fontSize: "1rem",
				[breakpoints.down("xs")]: {
					fontSize: "0.7rem"
				}
			}
			// h1: {
			// 	fontSize: "5rem",
			// 	[breakpoints.down("xs")]: {
			// 		fontSize: "3rem"
			// 	}
			// }
			
		},
		MuiListItemText: {
			root: {
				fontSize: "1rem",
				[breakpoints.down("xs")]: {
					fontSize: "0.2rem"
				}
			}
		},
		MuiPaper: {
			root: {
				backgroundColor: "#2C2C4E",
				color: "white"
			}
		},
		MuiDialog: {
			paper: {
				backgroundColor: "#2C2C4E",
				color: "white"
			}
		},
		MuiFormLabel: {
			root: {
				color: "#FFFFFF",
				"&$disabled": {
					color: "#FFFFFF",
					borderColor: "#FFFFFF"
				}
			}
		},
		MuiInputLabel: {
			root: {
				color: "#FFFFFF",
				borderColor: "#FFFFFF",
				"&$disabled": {
					color: "#FFFFFF",
					borderColor: "#FFFFFF"
				},
			}
		},
		MuiInputAdornment: {
			disablePointerEvents: {
				color: "#FFFFFF",
				backgroundColor: "#FFFFFF"
			},
			positionStart: {
				color: "#FFFFFF",
			},
			positionEnd: {
				color: "#FFFFFF",
				backgroundColor: "#FFFFFF"
			}
		},
		MuiTextField: {
			root: {
				color: "white",
				borderColor: "white",
				"&$disabled": {
					color: "#FFFFFF",
					borderColor: "#FFFFFF"
				},
			}

		},
		MuiAppBar: {
			root: {
				background: "linear-gradient(90deg, #CA21E5 0%, #7D7CF9 100%)"
			}
		},
		MuiDivider: {
			root: {
				backgroundColor: "white",
				color: "white",
				opacity: "20%"
			}
		},
	}
};

export default myTheme;