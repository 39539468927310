export const baseURL: string = "https://vitoy.herokuapp.com/client/user-management";
export const baseBonusURL: string = "https://vitoy.herokuapp.com/client";
export const loginURL: string = `${baseURL}/login/`;
export const registerURL: string = `${baseURL}/create-user/`;
export const logoutURL: string = `${baseURL}/logout/`;
export const sponsorURL: string = `${baseBonusURL}/bonus/bonus/`;
export const generationBonus: string = `${baseBonusURL}/bonus/history-bonus-generasi/`;
export const totalPurchase: string = `${baseBonusURL}/transaction/total-purchase/`;
export const totalBonues: string = `${baseBonusURL}/bonus/total-bonus/`;
export const totalOmset: string = `${baseBonusURL}/transaction/omset-group/`;

export const purchaseURL: string = `${baseBonusURL}/transaction/purchase/`;
export const historyPurchaseURL: string = `${baseBonusURL}/transaction/history-purchase/`;
export const historySponsorURL: string = `${baseBonusURL}/bonus/history-bonus-sponsor/`;
export const historyROEURL: string = `${baseBonusURL}/bonus/history-bonus-roe/`;
export const historyMatchingURL: string = `${baseBonusURL}/bonus/history-bonus-matching/`;

export const changePasswordURL: string = `${baseURL}/change-password/`;
export const graphROEURL: string = `${baseBonusURL}/bonus/grafik-roe/`; 

export const getGeneration: (number: string) => string = (number: string): string => `${baseURL}/hirarki-${number}/`;
export const getTotalCoin: (coin: string) => string = (coin: string): string => {
	return `${baseURL}/get-coin/${coin}/`;
};

export const checkPrice: string = `${baseBonusURL}/transaction/check-price/`;

// OTHER API
export const countryURL: string = "https://restcountries.eu/rest/v2/all?fields=name;alpha2Code;flag;callingCodes";
export const numVerify: string = `https://api.bigdatacloud.net/data/phone-number-validate?localityLanguage=en&key=${process.env.REACT_APP_PHONE}&number=`;
export const emailVerify: string = `https://api.bigdatacloud.net/data/email-verify?key=${process.env.REACT_APP_PHONE}&emailAddress=`;
