import { Slide, SlideProps, Snackbar } from "@material-ui/core";
import React from "react";

type NotificationProps = {
	open: boolean;
	message: string;
}

type TransitionProps = Omit<SlideProps, "direction">;

function TransitionDown(props: TransitionProps) {
	return <Slide {...props} direction="down" />;
}

export default function Notification(props: NotificationProps) {
	const { open, message } = props;
	const [transition] = React.useState<
    React.ComponentType<TransitionProps> | undefined
	>(() => TransitionDown);
	const [isClose, setIsClose] = React.useState<null | boolean>(null);

	React.useEffect(() => {
		setTimeout(() => {
			setIsClose(false);
		}, 3000);
	}, []);

	return (
		<Snackbar 
			open={isClose !== null ? isClose : open} 
			TransitionComponent={transition} 
			message={message}
		/>
	);
}