import React from "react";
import { Box, Button, Container, Grid, Paper, Typography, Link as LinkMui } from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { Loading, MyFormComponent, Notification } from "../components";
import { UserInput } from "../models/input.state";
import { LoadingHooks, UserInputHooks } from "../hooks";
import { Link, useHistory } from "react-router-dom";
import { decryptLinkUrl, getToken } from "../utils/auth";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			width: "100vw",
			height: "auto"
		},
		paper: {
			margin: theme.spacing(4),
			padding: theme.spacing(4),
			width: theme.spacing(70),
			[theme.breakpoints.down("sm")]: {
				width: theme.spacing(100)
			}
		},
		form: {
			display: "flex",
			flexDirection: "column",
			margin: theme.spacing(2),
		},
		fullWidth: {
			width: "100%",
		},
		bg: {
			backgroundColor: theme.palette.secondary.main
		},
		registerButton: {
			backgroundColor: theme.palette.primary.main,
			"&:hover": {
				backgroundColor: "#D2910F"
			}
		}
	}),
);

export default function RegisterPages(): JSX.Element {
	const classes = useStyles();
	const history = useHistory();

	// Textfields
	const labels: Array<keyof UserInput> = ["name", "phone", "email", "password", "refferal_code"];
	
	// hooks
	const { infoPage, setInfoPage } = LoadingHooks();
	const { useShowPassword, useHandleChange, useHandleSubmit, values, errorField } = UserInputHooks(setInfoPage);
	const [reff, setReff] = React.useState<string>("");

	React.useEffect(() => {
		getToken() && history.push("/dashboard");
	}, []);

	return(
		<div className={classes.bg}>
			{infoPage.loading && 
				<Loading open={infoPage.loading} />
			}

			<Container className={classes.root}>
				<Paper className={classes.paper} elevation={5}>
					<Grid container direction="column" alignItems="center" justify="center">
						<Grid item xs>
							<img src={process.env.PUBLIC_URL + "/assets/img/logo-png.png"} />
						</Grid>
						<Grid item xs>
							<Box mt={2}>
								<Typography variant="h4">Register</Typography>	
							</Box>
						</Grid>
						<Grid item xs className={classes.fullWidth}>
							<form className={classes.form} onSubmit={useHandleSubmit("register")} autoComplete="on">
								{labels.map((val) => (
									<Box className={classes.fullWidth} key={val} mt={2}>
										<MyFormComponent el={val} 
											onChange={useHandleChange} 
											onClick={useShowPassword} 
											values={values} 
											key={val} 
											errorField={errorField}
										/>
									</Box>
								))}
								<Box mt={5} className={classes.fullWidth}>
									<Grid container direction="column" alignItems="center" spacing={2}>
										<Grid item className={classes.fullWidth}>
											<Button className={classes.registerButton} type="submit" fullWidth variant="contained">Register</Button>
										</Grid>
										<Grid item xs>
											<Typography variant="subtitle1" >
										Already have account? <Link to="login"><LinkMui color="primary">Login here!</LinkMui></Link>
											</Typography> 
										</Grid>
									</Grid>
								</Box>
							</form>
						</Grid>
					</Grid>
				</Paper>
			</Container>
		</div>
	);
}