import React from "react";
import { reducerLoadingSnack } from "./reducer";

// @ts-ignore
import { useSnackbar } from "react-simple-snackbar";

export default () => {
	const [infoPage, setInfoPage] = React.useReducer(reducerLoadingSnack, {
		loading: false,
		hasError: false,
		errorMessage: ""
	});

	const [openSnackbar] = useSnackbar();
	React.useEffect(() => {
		if (infoPage.hasError) {
			openSnackbar(infoPage.errorMessage);
		}
	}, [infoPage.hasError]);

	return { infoPage, setInfoPage };
};