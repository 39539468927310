import { Box, Button, Container, createStyles, Divider, Grid, LinearProgress, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { History, IconButtonDashboard, LineChart, Loading, MyFormComponent, MyModal, PaperPanel } from "../components";
import { LoadingHooks, ResponseHooks, UserInputHooks } from "../hooks";
import modalHooks from "../hooks/modal.hooks";
import { generationBonus, historyMatchingURL, historySponsorURL, sponsorURL, totalBonues, totalOmset, totalPurchase } from "../lib/urls";
import { DashboardComponent, DashboardState } from "../models/dashboard.state";
import { BonusDashboard } from "../models/models/bonus.model";
import { DataHistoryGeneration, DataHistoryMatching, HistorySponsor } from "../models/models/history.model";
import { FetcherData } from "../models/models/util.model";
import { clearToken, getToken } from "../utils/auth";
import { fetcher } from "../utils/fetcher";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		bg: {
			backgroundColor: theme.palette.secondary.main
		},
		bonusIllustration: {
			width: "60%"
		},
		image: {
			[theme.breakpoints.down("sm")]: {
				width: theme.spacing(6),
				height: theme.spacing(6)
			}
		},
		imageButton: {
			[theme.breakpoints.down("sm")]: {
				width: theme.spacing(4),
				height: theme.spacing(4)
			}
		}
	}),
);
 
export default function Dashboard(): JSX.Element {
	const classes = useStyles();
	const { setInfoPage, infoPage } = LoadingHooks();
	const { responValue, setResponValue } = ResponseHooks();
	const { useHandleChange, values, errorField, useShowPassword, useHandleSubmit, dispatchValue } = UserInputHooks(setInfoPage, setResponValue, responValue);
	const { modalState, onCloseModal, openModal } = modalHooks(values, undefined, dispatchValue);

	const baseContent: JSX.Element = <>	
		<Grid item>
			<MyFormComponent
				el="amount purchase"
				errorField={errorField}
				values={values}
				onChange={useHandleChange}
				onClick={useShowPassword}
				key={"amount purchase"}
			/>
		</Grid>
		<Grid item>
			<MyFormComponent
				el="currency"
				errorField={errorField}
				values={values}
				onChange={useHandleChange}
				onClick={useShowPassword}
				key={"currency"}
			/>
		</Grid>
		<Grid item>
			{
				modalState.isLoading ?
					<LinearProgress color="primary" />
					:
					<>
						<Divider />
						<Typography variant="h6">
							{values.currency}
						</Typography>
						<Typography variant="h5">
							{values[values.currency]}
						</Typography>
					</>
			}
		</Grid>
	</>;

	const agreement: JSX.Element = <Grid item>
		<MyFormComponent
			el={"agree"}
			errorField={errorField}
			values={values}
			onChange={useHandleChange}
			onClick={useShowPassword}
			key="agree"
		/>
	</Grid>;

	const donePurchase: JSX.Element = <>
		<Grid item>
			<Typography variant="subtitle1" style={{ margin: "0 auto " }}>Send to: 
				<Typography variant="h6">{responValue.address} </Typography>
			</Typography>
			<Typography variant="subtitle1" style={{ margin: "0 auto " }}>Total coin: 
				<Typography variant="h6">{responValue.coin === "DOGE" ? Math.ceil(Number(responValue.nominal)) : responValue.nominal}</Typography>
			</Typography>
			<Typography variant="subtitle1" style={{ margin: "0 auto " }}>Type coin: 
				<Typography variant="h6">{responValue.coin}</Typography>
			</Typography>
		</Grid>
	</>;

	const purchaseContent: JSX.Element = <Grid container direction="column" spacing={5} >
		{values.isDone ?
			donePurchase
			:
			<>
				{baseContent}
				{agreement}
			</>
		}
	</Grid>;

	const withdrawContent: JSX.Element = <Grid container direction="column" spacing={5}>
		{baseContent}
		<Grid item>
			<MyFormComponent
				el={"address"}
				errorField={errorField}
				values={values}
				onChange={useHandleChange}
				onClick={useShowPassword}
				key="address"
			/>
		</Grid>
		{agreement}
	</Grid>;

	const buttons: Array<DashboardComponent> = [
		{
			title: "Purchase",
			icon: <img className={classes.imageButton} src={process.env.PUBLIC_URL + "/assets/icon/cart-icon-white.svg"} alt="icon purchase" />
		},
		{
			title: "Withdrawal",
			icon: <img className={classes.imageButton} src={process.env.PUBLIC_URL + "/assets/icon/withdraw-icon-white.svg"} alt="icon withdraw" />
		}
	];

	// states data
	const [loading, setLoading] = React.useState<boolean>(false);
	const [infos, setInfos] = React.useState<DashboardState[]>([]);
	const [labels, setLabels] = React.useState<DashboardState[]>([]);
	const [sponsor, setSponsor] = React.useState<DashboardState[]>([]);
	const [generasi, setGenerasi] = React.useState<DashboardState[]>([]);
	const [matching, setMatching] = React.useState<DashboardState[]>([]);

	// hooks router
	const history = useHistory();

	// get data
	const getLabels = async (type: string) => {
		const response = await fetcher("GET", type);

		if (response.status === 500) {
			throw Error("Something went wrong, please try again!");
		}

		if (response.status === 401) {
			clearToken(getToken());
			throw Error("Please re-login!");
		}

		const result: FetcherData = await response.json();
		const data: string = result.data as string;

		return data;
	};

	const getBonusInfos = async () => {
		const response = await fetcher("GET", sponsorURL);

		if (response.status === 500) {
			throw Error("Something went wrong, please try again!");
		}

		if (response.status === 401) {
			clearToken(getToken());
			history.push("/");
			throw Error("Please re-login!");
		}

		const result: FetcherData = await response.json();
		const { bonus_generasi, bonus_matching, bonus_roe, bonus_sponsor } = result.data as BonusDashboard;

		const data = [
			{
				title: "Bonus Sponsor",
				value: bonus_sponsor ?? 0
			},
			{
				title: "Bonus Generation",
				value: bonus_generasi ?? 0
			},
			{
				title: "Matching ROE",
				value: bonus_matching ?? 0
			},
			{
				title: "Bonus ROE",
				value: bonus_roe ?? 0
			},
		];
		setInfos(data);

	};

	const getHistorySponsor = async () => {
		const response = await fetcher("GET", historySponsorURL);

		if (response.status === 500) {
			throw Error("Something went wrong, please try again!");
		}

		if (response.status === 401) {
			clearToken(getToken());
			throw Error("Please re-login!");
		}

		const result: FetcherData = await response.json();
		const data = result.data as HistorySponsor[];

		const dataSponsor: DashboardState[] = [
			{
				title: "Date",
				value: "Value"
			}
		];

		for (const i of data) {
			dataSponsor.push({
				title: i.created_date,
				value: i.bonus
			});
		}

		setSponsor(dataSponsor.slice(0, 6));
	};

	const getGenerationBonus = async () => {
		const response = await fetcher("GET", generationBonus);

		if (response.status === 500) {
			throw Error("Something went wrong, please try again!");
		}

		if (response.status === 401) {
			clearToken(getToken());
			throw Error("Please re-login!");
		}

		const result = await response.json();
		const data: DataHistoryGeneration = result.data;
		
		const dataGeneration: DashboardState[] = [{
			title: "Date",
			value: "Value"
		}];
		let i: keyof DataHistoryGeneration;

		for (i in data) {
			for (const j of data[i]) {
				dataGeneration.push({
					title: j.created_date.toString(),
					value: j.user_bonus
				});
			}
		}

		setGenerasi(dataGeneration.slice(0, 6));
	};

	const getHistoryMatching = async () => {
		const response = await fetcher("GET", historyMatchingURL);

		if (response.status === 500) {
			throw Error("Something went wrong, please try again!");
		}

		if (response.status === 401) {
			clearToken(getToken());
			throw Error("Please re-login!");
		}
		
		const result = await response.json();
		const data: DataHistoryMatching = result.data;
		
		const dataGeneration: DashboardState[] = [{
			title: "Date",
			value: "Value"
		}];
		let i: keyof DataHistoryMatching;

		for (i in data) {
			for (const j of data[i]) {
				dataGeneration.push({
					title: j.created_date.toString(),
					value: j.bonus
				});
			}
		}

		setMatching(dataGeneration.slice(0, 6));
	};

	const getAllData = async () => {
		try {
			getBonusInfos();
			const purchase = await getLabels(totalPurchase);
			const omset = await getLabels(totalOmset);
			const bonus = await getLabels(totalBonues);
			await getHistorySponsor();
			await getGenerationBonus();
			await getHistoryMatching();
	
			setLabels([
				{
					title: "Moment Purchase",
					value: purchase ?? 0,
					icon: <img className={classes.image} src={process.env.PUBLIC_URL + "/assets/icon/cart-icon.svg"} alt="image-icon" />
				},
				{
					title: "Omzet Group",
					value: omset ?? 0,
					icon: <img className={classes.image} src={process.env.PUBLIC_URL + "/assets/icon/omzet-icon.svg"} alt="image-icon" />
				},
				{
					title: "Total Bonus",
					value: bonus ?? 0,
					icon: <img className={classes.image} src={process.env.PUBLIC_URL + "/assets/icon/bonus-icon.svg"} alt="image-icon" />
				},
				{
					title: "Total Withdrawal",
					value: 0,
					icon: <img className={classes.image} src={process.env.PUBLIC_URL + "/assets/icon/withdraw-icon.svg"} alt="image-icon" />
				},
			]);
		} catch (error) {
			setInfoPage({ type: "errorMessage", value: error.message ?? "Something went wrong!" });
			setInfoPage({ type: "hasError", value: true });
		} finally {
			setLoading(false);
			setInfoPage({ type: "hasError", value: false });
		}
	};

	// methods
	React.useEffect(() => {
		if (infos.length < 1 || labels.length < 1) {
			setLoading(true);
		}

		getAllData(); 
	}, []);

	return (
		<Container className={classes.bg}>
			<Loading open={infoPage.loading || loading} />

			<Grid container direction="column" spacing={3}>
				<Grid item container direction="row" spacing={3}>
					{labels.map((el: DashboardState) => (
						<Grid item lg={3} md={6} sm={12} xs={12} key={el.title}>
							<PaperPanel title={el.title}  value={Number(el.value)} icon={el.icon} />
						</Grid>
					))}
				</Grid>
				<Grid item container direction="row" spacing={3}>
					{buttons.map((el: DashboardComponent) => (
						<Grid item lg={6} md={6} sm={12} xs={12} key={el.title}>
							<IconButtonDashboard title={el.title} icon={el.icon} action={openModal}/>
						</Grid>
					))}
				</Grid>
				<Grid item container spacing={3} alignItems="flex-start">
					<Grid item lg={8} md={8} sm={12} xs={12}>
						<LineChart />
					</Grid>
					<Grid item lg={4} md={4} sm={12} xs={12}>
						<Box my={2}>
							<Paper elevation={0}>
								<Grid container direction="column" spacing={5}>
									<Box p={5}>
										<Box mb={3}>
											<Grid item container justify="center" alignItems="center">
												<img className={classes.bonusIllustration} src={process.env.PUBLIC_URL + "/assets/img/bonus-illustration.png"} />
											</Grid>
										</Box>
										{infos.map((el: DashboardState) => (
											<Grid item key={el.title}>
												<Typography variant="h6">{el.title}</Typography>
												<Typography variant="body1">{el.value}</Typography>
											</Grid>
										))}
									</Box>
								</Grid>
							</Paper>
						</Box>
					</Grid>
				</Grid>
				<Grid item container spacing={3}>
					<Grid item lg={4} md={4} sm={12} xs={12}>
						<History title="Sponsor" data={sponsor} />
					</Grid>
					<Grid item lg={4} md={4} sm={12} xs={12}>
						<History title="Generasi" data={generasi}/>
					</Grid>
					<Grid item lg={4} md={4} sm={12} xs={12}>
						<History title="Matching ROE" data={matching}/>
					</Grid>
				</Grid>				
			</Grid>

			<MyModal 
				onClose={onCloseModal}
				isOpen={modalState.isOpen}
				actions={	
					<Button  fullWidth color="primary" variant="contained" 
						onClick={useHandleSubmit(modalState.title)}
						disabled={!values.agree || values.eth === ""}
					>
						{values.isDone ? "Finish" : modalState.title}
					</Button>
				}
				title={values.isDone ? "Finish" : modalState.title}
				contents={
					modalState.title.toLocaleLowerCase() === "purchase now" ? purchaseContent : withdrawContent
				}
			/>
		</Container>
	);
}