import { AppBar, Container, createStyles, Grid, Box, IconButton, makeStyles, Theme, Typography } from "@material-ui/core";
import { ExitToApp, Menu } from "@material-ui/icons";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Notification } from "..";
import { LoadingHooks, NavbarHooks } from "../../hooks";
import { logoutURL } from "../../lib/urls";
import { clearToken, getToken, getUser } from "../../utils/auth";
import { fetcher } from "../../utils/fetcher";

// @ts-ignore
import { useSnackbar } from "react-simple-snackbar";
import { User } from "../../models/models/user.model";
import Navbar from "./navbar";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: "100%",
		},
		title: {
			flexGrow: 1,
			"& > *": {
				width: theme.spacing(13)
			}
		},
		padding: {
			width: "100%"
		},
		header: {
			display: "flex",
			flexDirection: "row",
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1)
		},
		image: {
			width: theme.spacing(5),
			height: theme.spacing(5)
		},
		color: {
			color: "white"
		},
		// menuButton: {
		// 	// display: "none",
		// 	[theme.breakpoints.down("xs")]: {
		// 		display: "block",
		// 	},
		// 	[theme.breakpoints.down("sm")]: {
		// 		display: "block",
		// 	},
		// 	[theme.breakpoints.down("md")]: {
		// 		display: "none",
		// 	},
		// 	[theme.breakpoints.down("lg")]: {
		// 		display: "none",
		// 	},
		// 	[theme.breakpoints.down("xl")]: {
		// 		display: "none",
		// 	},
		// }
	}),
);

export default function MyAppBar () {
	const classes = useStyles();
	const history = useHistory();
	const [isLogout, setIsLogout] = React.useState<boolean>(false);
	const [openSnackbar] = useSnackbar();
	const { setInfoPage } = LoadingHooks();
	const [name, setName] = React.useState<string>("");

	const { isOpen, openNavbar } = NavbarHooks();


	React.useEffect(() => {
		const user: User = getUser();
		setName(user.name);
	}, []);
	
	const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		fetcher("GET", logoutURL);
		clearToken(getToken());
		setIsLogout(true);
		history.push("/login");
		setInfoPage({ type: "loading", value: true });
		openSnackbar("Success Logout!");
	};

	return (
		<>
			<AppBar position="static" className={classes.padding} >
				<Container className={classes.header}>
					<Box display={{ xs: "block", sm: "block", md: "none" }}>
						<IconButton edge="start" color="inherit" aria-label="menu" onClick={openNavbar}>
							<Menu />
						</IconButton>
					</Box>
					<Link to="/" className={classes.title}>
						<img src={process.env.PUBLIC_URL + "/assets/img/logo-png.png"} alt="vcc logo"/>
					</Link>
					<Grid container direction="row" justify="flex-end" alignItems="center">
						<Grid item>
							<Typography variant="h6">{name}</Typography>
						</Grid>
						<Grid item>
							<IconButton edge="end" color="secondary" onClick={handleClick}>
								<ExitToApp fontSize="large" className={classes.color}/>
							</IconButton>
						</Grid>
					</Grid>
				</Container>
				{isLogout && 
				<Notification
					message="Logout success!"
					open={isLogout}
				/>
				}
			</AppBar>
			<Grid item xs style={{ margin: "5vw 0" }}>
				{isOpen && <Navbar />}
			</Grid> 
		</>
	);
}