import { Backdrop, CircularProgress, createStyles, makeStyles, Theme } from "@material-ui/core";
import React from "react";

type LoadingProps = {
	open: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		backdrop: {
			zIndex: theme.zIndex.drawer + 1,
			color: "#fff",
		},
	}),
);

export default function Loading(props: LoadingProps) {
	const { open } = props;
	const classes = useStyles();

	return (
		<Backdrop open={open} className={classes.backdrop}>
			<CircularProgress color="primary" size={80} />
		</Backdrop>
	);
}