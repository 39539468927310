import React from "react";

export default () => {
	const [isOpen, setIsOpen] = React.useState<boolean>(false);

	React.useEffect(() => {
		const size = window.innerWidth;
		setIsOpen(size < 960 ? false : true);
	}, []);

	const openNavbar = () => {
		setIsOpen(!isOpen);
	};

	return { isOpen, openNavbar };
};