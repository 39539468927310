/* eslint-disable no-unused-vars */
import { Collapse, List, ListItem, ListItemText, Paper } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import React from "react";
import { GenerationListData } from ".";
import { GenerationData } from "../models/generation.state";

type HistoryProps = {
	el: string,
	showCollapse: (attr: string) => () => void,
	open: boolean,
	data: Array<GenerationData>
}

export default function HistoryComponent (props: HistoryProps): JSX.Element {
	const { el, showCollapse, open, data } = props;

	return (
		<Paper>
			<List component="nav" onClick={showCollapse(el)}>
				<ListItem button>
					<ListItemText primary={el} secondary={`Show data ${el}`} />
					{open ? <ExpandLess /> : <ExpandMore />}
				</ListItem>
			</List>
			<Collapse in={open} timeout="auto" unmountOnExit>
				<GenerationListData values={data}/>
			</Collapse>
		</Paper>
	);
}
