import { Container, createStyles, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { Dashboard, History, AttachMoney, Explore, Person } from "@material-ui/icons";
import React from "react";
import { NavLink } from "react-router-dom";
import { NavbarHooks } from "../../hooks";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		notActive: {
			backgroundColor: theme.palette.secondary.main,
			color: theme.palette.secondary.contrastText,
			textDecorationLine: "none",
			textTransform: "capitalize",
			display: "flex",
			flexDirection: "row",
			padding: theme.spacing(2),
			"& > *": {
				// margin: theme.spacing(2)
			}
		},
		active: {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,
			textDecorationLine: "none",
			textTransform: "capitalize",
			display: "flex",
			flexDirection: "row",
			padding: theme.spacing(2),
			"& > *": {
				// margin: theme.spacing(2)
			}
		}
	}),
);

export default function Navbar () {
	const classes = useStyles();

	const navbarItem = [
		{
			icon: <Dashboard />,
			name: "dashboard"
		},
		{
			icon: <History />,
			name: "hierarchy"
		},
		{
			icon: <AttachMoney />,
			name: "moneytory"
		},
		{
			icon: <Explore />,
			name: "explore"
		},
		{
			icon: <Person />,
			name: "profile"
		},
	];

	const { openNavbar } = NavbarHooks();
	
	return (
		<Container onClick={openNavbar}> 
			<Grid container direction="row">
				{navbarItem.map((el: {icon: JSX.Element, name: string}) => (
					<Grid item lg={"auto"} md={"auto"} sm={12} xs={12} key={el.name + "-grids"}>
						<NavLink key={el.name} to={"/" + el.name} className={classes.notActive} activeClassName={classes.active}>
							<Grid item>
								{el.icon}
							</Grid>
							<Grid item xs>
								<Typography variant="subtitle1">{el.name}</Typography>
							</Grid>
						</NavLink>
					</Grid>
				))}
				<Grid item xs={4} md={8}>
				</Grid>
			</Grid>
		</Container>
	);
}