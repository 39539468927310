import React from "react";
import { Container, Grid } from "@material-ui/core";
import { Loading, MyListComponent } from "../components";
import { HistoryHooks, LoadingHooks } from "../hooks";
import { GenerationData } from "../models/generation.state";
import { fetcher } from "../utils/fetcher";
import { getGeneration } from "../lib/urls";
import { FetcherData } from "../models/models/util.model";
import { clearToken, getToken } from "../utils/auth";

export default function Hierarchy(): JSX.Element {
	// data this pages
	const category: Array<string> = [
		"Generation 1", 
		"Generation 2", 
		"Generation 3", 
		"Generation 4",
		"Generation 5",
		"Generation 6",
		"Generation 7",
	];

	// states
	const [data, setData] = React.useState<GenerationData[][]>([]);
	const [loading, setLoading] = React.useState<boolean>(false);

	// hooks
	const { infoPage, setInfoPage } = LoadingHooks();

	// react fecther
	const getHierarchy = async (number: string) => {
		const response = await fetcher("GET", getGeneration(number));
		if (response.status === 500) {
			throw Error("Something went wrong, please try again!");
		}

		if (response.status === 401) {
			clearToken(getToken());
			throw Error("Please re-login!");
		}
		const result: FetcherData = await response.json();
		const data = result.data as GenerationData[];

		const generationData: GenerationData[] = [{
			no: 0,
			name: "",
			email: "",
			phone: ""
		}];
		let no: number = 1;

		for (const i of data) {
			generationData.push({
				no: no,
				name: i.name,
				email: i.email,
				phone: i.phone
			});
			no++;
		}
		return generationData;
	};

	const getAllData = async () => {
		try {
			const hirarchyOne = await getHierarchy("1");
			const hirarchyTwo = await getHierarchy("2");
			const hirarchyThree = await getHierarchy("3");
			const hirarchyFour = await getHierarchy("4");
			const hirarchyFive = await getHierarchy("5");
			const hirarchySix = await getHierarchy("6");
			const hirarchySeven = await getHierarchy("7");
	
			setData([
				hirarchyOne,
				hirarchyTwo,
				hirarchyThree,
				hirarchyFour,
				hirarchyFive,
				hirarchySix,
				hirarchySeven
			]);
		} catch (error) {
			setInfoPage({ type: "errorMessage", value: error.message ?? "Something went wrong!" });
			setInfoPage({ type: "hasError", value: true });
		} finally {
			setInfoPage({ type: "hasError", value: false });
			setLoading(false);
		}
	};
	
	React.useEffect(() => {
		if (data.length < 6) {
			setLoading(true);
		}

		getAllData();
	}, []);
	
	// Hooks
	const { open, showCollapse } = HistoryHooks();

	return (
		<Container>
			<Loading open={loading} />
			<Grid container direction="row" spacing={5}>
				{category.map((el: string, index: number) => (
					<Grid item xs={12} key={el}>
						<MyListComponent el={el} open={open[el]} showCollapse={showCollapse} data={data[index]}/>
					</Grid>
				))}
			</Grid>
		</Container>
	);
}