import { CssBaseline, Grid, ThemeProvider } from "@material-ui/core";
import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { MyAppBar, Navbar } from "./components";
import myTheme from "./lib/theme";
import { getToken } from "./utils/auth";
import { DashboardPage, HierarchyPage, LoginPage, MoneytoryPage, RegisterPage, ProfilePage } from "./pages";

// @ts-ignore
import SnackbarProvider from "react-simple-snackbar";


function App(): JSX.Element {
	const AuthRoute = ({ ...rest }) => {
		document.body.style.backgroundColor = myTheme.palette.secondary.main;
		return (<Route {...rest} render={props => (
			getToken() ?
				<Grid container direction="column" style={{ backgroundColor: myTheme.palette.secondary.main }}>
					<Grid item xs>
						<MyAppBar />
					</Grid>
					<Grid item xs>
						<Switch>
							<Route exact path="/dashboard" render={props => <DashboardPage />} />
							<Route exact path="/hierarchy" render={props => <HierarchyPage />} />
							<Route exact path="/moneytory" render={props => <MoneytoryPage />} />
							<Route exact path="/profile" render={props => <ProfilePage />} />
						</Switch>
					</Grid>
				</Grid>
				:
				<Redirect to="/login" />
		)}
		/>
		);};

	document.body.style.backgroundColor = myTheme.palette.primary.main;

	return (
		<ThemeProvider theme={myTheme}>
			<CssBaseline />
			<BrowserRouter>
				<SnackbarProvider>
					<Switch>
						<Route exact path="/">
							<Redirect to="/login" />
						</Route>
						<Route exact path="/login" render={props => <LoginPage  />}/>
						<Route exact path="/register" render={props => <RegisterPage  />} />
						<AuthRoute path="*" />
					</Switch>
				</SnackbarProvider>
			</BrowserRouter>
		</ThemeProvider>
	);
}

export default App;