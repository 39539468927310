import React from "react";
import { Container, createStyles, Grid, makeStyles, Paper, Theme, Typography, Divider, Button, IconButton, Box, TextField } from "@material-ui/core";
import { AccountCircle, FileCopy } from "@material-ui/icons";
import { getUser, hashLinkUrl } from "../utils/auth";
import { User } from "../models/models/user.model";
import CopyToClipboard from "react-copy-to-clipboard";
import { LoadingHooks, ModalHooks, UserInputHooks } from "../hooks";
import { MyFormComponent, MyModal } from "../components";


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		bg: {
			backgroundColor: theme.palette.secondary.main
		},
		buttonEdit: {
			padding: "auto 40px",
			width: "80px"
		},
		buttonChangePassword: {
			background: "#312F2F",
			"&:hover": {
				backgroundColor: "#151414"
			}
		},
		width: {
			width: "40vw",
			[theme.breakpoints.down("sm")]: {
				width: "80vw"
			}
		}
	}), 
);

export default function Profile(): JSX.Element { 
	const classes = useStyles();
	
	// states data
	const [user, setUser] = React.useState<User>();
	const [hashLink, setHashLink] = React.useState<string>("");
	const [copied, setCopied] = React.useState<boolean>(false);
	const { setInfoPage } = LoadingHooks();

	const { useHandleSubmit, values, dispatchValue, errorField, useHandleChange, useShowPassword } = UserInputHooks(setInfoPage);
	const { modalState, onCloseModal, openModal } = ModalHooks(values, undefined, dispatchValue);

	const hashingReff = (link: string) => {
		const linked = hashLinkUrl(link);
		setHashLink(linked);
	};

	const onCopy = () => {
		setInfoPage({ type: "errorMessage", value: "Link copied to clipboard" });
		setInfoPage({ type: "hasError", value: true });
		setCopied(true);
	};

	// fetcher
	React.useEffect(() => {
		const userData: User = getUser();
		setUser(userData);
		hashingReff("refferal=" + userData.referal_code);
	}, []);

	return (
		<Container>
			<Grid container direction="column" spacing={5} alignContent="center" justify="center">
				<Grid item alignItems="center" justify="center">
					<Paper elevation={0} color="theme.palette.primary.contrastText" className={classes.width}>
						<Box py={4} px={6}>
							<Grid container direction="column" spacing={2}>
								<Grid item container direction="row" alignItems="center" justify="center">
									<AccountCircle color="primary" style={{ fontSize: 150 }} />
								</Grid>
								<Grid item container spacing={5} alignItems="center" justify="center">
									<Typography variant="h4">{user?.name}</Typography>
								</Grid>
								<Box my={5}>
									<Divider />
								</Box>
								<Grid container direction="column" spacing={3}>
									<Grid item>
										<Typography variant="subtitle1">Email</Typography>
										<Typography variant="h6">{user?.email}</Typography>
									</Grid>
									<Grid item>
										<Typography variant="subtitle1">No. Handphone</Typography>
										<Typography variant="h6">{user?.phone}</Typography>
									</Grid>
									<Grid item>
										<Button className={classes.buttonEdit} variant="contained" color="primary" disableElevation>Edit</Button>
									</Grid>
								</Grid>
							</Grid>
						</Box>
					</Paper >
				</Grid>
				<Grid item alignItems="center" justify="center">
					<Paper elevation={0} color="theme.palette.primary.contrastText">
						<Box py={4} px={6}>
							<Grid container direction="column" spacing={3}>
								<Grid item container direction="row" alignItems="center">
									<Grid item xs={8} container direction="column">
										<Grid item>
											<Typography variant="subtitle1">Kode Referral</Typography>
										</Grid>
										<Grid item>
											<Typography variant="h6">{user?.referal_code}</Typography>
										</Grid>
									</Grid>
									<Grid item xs={4} container justify="flex-end">
										<CopyToClipboard text={`https://vccom.trade/register?${hashLink}`} onCopy={onCopy}>
											<IconButton arial-label="copy">
												<FileCopy color="primary"/>
											</IconButton>
										</CopyToClipboard>
									</Grid>
								</Grid>
								<Grid item>
									<Typography variant="subtitle1">Link Referral</Typography>
								</Grid>
								<Grid item xs={12}>
									<TextField value={`https://vccom.trade/register?${hashLink}`} fullWidth/>
									{copied && <Typography variant="subtitle2"><Box fontStyle="italic">Link copied to clipboard</Box></Typography>}
								</Grid>
							</Grid>
						</Box>
					</Paper>
				</Grid>
				<Grid item alignItems="center" justify="center">
					<Button className={classes.buttonChangePassword} variant="contained" color="primary" fullWidth={true} disableElevation
						onClick={openModal("change password")}
					>Ganti Password</Button>
				</Grid>
			</Grid>

			<MyModal
				title="Change Password"
				isOpen={modalState.isOpen}
				onClose={onCloseModal}
				actions={
					<Button  fullWidth color="primary" variant="contained" 
						onClick={useHandleSubmit(modalState.title)}
					>
						Change Password
					</Button>
				}
				contents={
					<Grid container direction="column" spacing={6}>
						<Grid item>
							<MyFormComponent
								el="old password"
								errorField={errorField}
								values={values}
								onChange={useHandleChange}
								onClick={useShowPassword}
							/>
						</Grid>
						<Grid item>
							<MyFormComponent
								el="password"
								errorField={errorField}
								values={values}
								onChange={useHandleChange}
								onClick={useShowPassword}
							/>
						</Grid>
					</Grid>
				}
			/>
		</Container>
	);
}

// el={"agree"}
// errorField={errorField}
// values={values}
// onChange={useHandleChange}
// onClick={useShowPassword}
// key="agree"

// onClose={onCloseModal}
// isOpen={modalState.isOpen}
// actions={	
// 	<Button  fullWidth color="primary" variant="contained" 
// 		onClick={useHandleSubmit(modalState.title)}
// 		disabled={!values.agree || values.eth === ""}
// 	>
// 		{values.isDone ? "Finish" : modalState.title}
// 	</Button>
// }
// title={values.isDone ? "Finish" : modalState.title}
// contents={
// 	modalState.title.toLocaleLowerCase() === "purchase now" ? purchaseContent : withdrawContent
// }