import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { GenerationData } from "../../models/generation.state";
import { Generation, MatchingROE, Purchase, ROE, Sponsor, Withdraw } from "../../models/moneytory.state";

const useStyles = makeStyles({
	table: {
		minWidth: 650,
	},
});

type GenerationListProps = {
	values: Array<GenerationData | MatchingROE | Purchase | Withdraw | Sponsor | ROE | Generation>
};

export default function GenerationListData(props: GenerationListProps) {
	const { values } = props;
	const classes = useStyles();

	return (
		<TableContainer component={Paper}>
			<Table className={classes.table} size="small" aria-label="a dense table">
				{values.map((el: GenerationData | MatchingROE | Purchase | Withdraw | Sponsor | ROE | Generation, index: number) => (
					index === 0 ?
						<TableHead key={index + Math.random()}>
							<TableRow>
								{Object.keys(el).map((val) => (
									<TableCell key={val}>{val}</TableCell>
								))}
							</TableRow>
						</TableHead>
						:
						<TableBody key={index + Math.random()}>
							<TableRow >
								{Object.values(el).map((val) => (
									<TableCell component="th" scope="row" key={val}>
										{val}
									</TableCell>
								))}
							</TableRow>
						</TableBody>
				))}
			</Table>
		</TableContainer>
	);
}
