import { Box, Button, Grid, Typography, createStyles, makeStyles, Theme } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() => 
	createStyles({
		buttonPurchases: {
			color: "white",
			backgroundColor: "#55B559",
			"&:hover": {
				backgroundColor: "#388E3C"
			},
		},
		buttonWithdraw: {
			backgroundColor: "#2196F3",
			color: "white",
			"&:hover": {
				backgroundColor: "#1976D2"
			},
		},
	}),
);

type IconButtonDashboardProps = {
	icon: JSX.Element | undefined,
	title: string,
	action: (type: string) => () => void
}

export default function IconButtonDashboard(props: IconButtonDashboardProps): JSX.Element {
	const { icon, title, action } = props;
	const classes = useStyles();

	return (
		<Button className={title === "Withdrawal" ? classes.buttonWithdraw : classes.buttonPurchases} variant="contained" fullWidth onClick={action(title)}>
			<Box p={2}>
				<Grid container direction="column">
					<Grid item container direction="row" alignItems="center">
						<Grid item xs={6}>
							{icon}
						</Grid>
						<Grid item xs={6}>
							<Typography variant="body2">
								{title}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</Button>
	);
}
