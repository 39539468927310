import React from "react";
import { ActionUserInput, UserInput } from "../models/input.state";
import { FinishPurchase } from "../models/models/purchase.model";
import { reducerMyModal } from "./reducer";

export default(values: UserInput, responseValue?: FinishPurchase, disPatchValue?: React.Dispatch<ActionUserInput>) => {
	const onCloseModal = () => {
		setMoodalState({ type: "isOpen", value: false });

		if (disPatchValue) {
			disPatchValue({ type: "isModalOpening", value: false });
		}
	};

	const openModal = (type: string) => () => {
		if (type.toLowerCase() === "purchase") {
			setMoodalState({ type: "isOpen", value: true });
			setMoodalState({ type: "title", value: "Purchase Now" });
		}

		if (type.toLowerCase() === "withdrawal") {
			setMoodalState({ type: "isOpen", value: true });
			setMoodalState({ type: "title", value: "Withdraw" });
		}

		if (type.toLocaleLowerCase() === "change password") {
			setMoodalState({ type: "isOpen", value: true });
			setMoodalState({ type: "title", value: "Change Password" });
		}

		if (disPatchValue) {
			disPatchValue({ type: "isModalOpening", value: true });
		}
	};

	
	const [modalState, setMoodalState] = React.useReducer(reducerMyModal, {
		isOpen: false,
		title: "Modal",
		onClose: onCloseModal,
		isLoading: true,
		address: "sfasfafas",
	});

	const getAddress = () => {
		if (values.eth === "" || values.doge === "") {
			setMoodalState({ type: "isLoading", value: true });
		} else {
			setMoodalState({ type: "isLoading", value: false });
		}
	};

	React.useEffect(() => {
		getAddress();
	}, [values.currency, values["amount purchase"], values.doge, values.eth]);

	React.useEffect(() => {
		if (values.isSubmit) {
			setMoodalState({ type: "isLoading", value: true });
		} else {
			setMoodalState({ type: "isLoading", value: false });
			setMoodalState({ type: "isOpen", value: false });

			if (disPatchValue) {
				disPatchValue({ type: "isModalOpening", value: false });
			}
		}

		if (values.isDone) {
			setMoodalState({ type: "isLoading", value: false });
		}
	}, [values.isSubmit, values.isDone]);
	
	return { modalState, onCloseModal, openModal, setMoodalState };
};