import React from "react";
import { Container, Grid } from "@material-ui/core";
import { Loading, MyListComponent } from "../components";
import { HistoryHooks, LoadingHooks } from "../hooks";
import { Purchase, ROE, Sponsor, Withdraw, Generation, MatchingROE } from "../models/moneytory.state";
import { fetcher } from "../utils/fetcher";
import { generationBonus, historyMatchingURL, historyPurchaseURL, historyROEURL, historySponsorURL } from "../lib/urls";
import { DataHistoryGeneration, DataHistoryMatching, HistoryGeneration, HistoryROE, HistorySponsor } from "../models/models/history.model";
import { HistoryPurchase } from "../models/models/purchase.model";
import { FetcherData } from "../models/models/util.model";
import { clearToken, getToken } from "../utils/auth";


export default function MoneytoryPage(): JSX.Element {
	// data this pages
	const category: Array<string> = [
		"History Purchase", 
		"Withdrawal", 
		"Return Of Equity (ROE)", 
		"Sponsor",
		"Generation",
		"Matching ROE"
	];

	const withdraw: Array<Withdraw> = [
		{
			no: 0,
			date: "",
			total: "",
			method: "ETH",
			coin: "",
		},
	];

	// state all data that willl be shown
	const [allData, setAllData] = React.useState<any[][]>([]);
	const [loading, setLoading] = React.useState<boolean>(false);
	const { infoPage, setInfoPage } = LoadingHooks();

	const getGenerationBonus = async () => {
		const response = await fetcher("GET", generationBonus);
		if (response.status === 500) {
			throw Error("Something went wrong, please try again!");
		}

		if (response.status === 401) {
			clearToken(getToken());
			throw Error("Please re-login!");
		}
		const result = await response.json();
		const data: DataHistoryGeneration = result.data;
		
		const dataGeneration: Generation[] = [];
		let i: keyof DataHistoryGeneration;
		let no: number = 1;

		for (i in data) {
			for (const j of data[i]) {
				dataGeneration.push({
					no: no,
					date: j.created_date.toString(),
					name: j.name,
					generation: `Generation ${i}`,
					"total purchase": `$ ${j.total_purchase}`,
					"bonus generation": `$ ${j.user_bonus}`
				});
			}
			if (data[i].length !== 0) {
				no++;
			}
		}

		// for table
		dataGeneration.unshift({
			no: 0,
			date: "20/08/2020",
			name: "Joko Bobo",
			generation: "Generation 1",
			"total purchase": "",
			"bonus generation": ""
		});

		return dataGeneration;
	};

	const getHistoryPurchase = async () => {
		const response = await fetcher("GET", historyPurchaseURL);
		if (response.status === 500) {
			throw Error("Something went wrong, please try again!");
		}

		if (response.status === 401) {
			clearToken(getToken());
			throw Error("Please re-login!");
		}
		const result = await response.json();
		const data = result.data as HistoryPurchase[];

		const dataHistory: Purchase[] = [{
			no: 0,
			// date: "",
			address: "",
			method: "0",
			coin: 0,
			nominal: "0",
			status: "0",
			// user: ""
		}];

		// let i;
		let no: number = 1;

		for (const i of data) {
			let status: string = "pending";
			if (i.status < 0) {
				status = "failed";
			}

			if (i.status >= 0) {
				status = "pending";
			}

			if (i.status >= 100) {
				status = "success";
			}
			dataHistory.push({
				no,
				address: i.address,
				method: i.coin,
				coin: i.coin === "DOGE" ? Math.ceil(i.coin_value) : i.coin_value,
				nominal: `$ ${i.nominal}`,
				status: status,
				// user: i.user
			});
			no++;
		}
		return dataHistory;
	};

	const getHistorySponsor = async () => {
		const response = await fetcher("GET", historySponsorURL);
		if (response.status === 500) {
			throw Error("Something went wrong, please try again!");
		}

		if (response.status === 401) {
			clearToken(getToken());
			throw Error("Please re-login!");
		}
		const result: FetcherData = await response.json();
		const data = result.data as HistorySponsor[];

		const dataSponsor: Sponsor[] = [
			{
				no: 0,
				date: "",
				downline: "",
				"total purchase": "",
				"bonus sponsor": ""
			}
		];

		let no: number = 1;

		for (const i of data) {
			dataSponsor.push({
				no: no,
				date: i.created_date,
				downline: i.name,
				"total purchase": `$ ${i.purchase.toString()}`,
				"bonus sponsor": `$ ${i.bonus.toString()}`
			});

			no++;
		}

		return dataSponsor;
	};

	const getHistoryROE = async () => {
		const response = await fetcher("GET", historyROEURL);
		if (response.status === 500) {
			throw Error("Something went wrong, please try again!");
		}

		if (response.status === 401) {
			clearToken(getToken());
			throw Error("Please re-login!");
		}
		const result: FetcherData = await await response.json();
		const data: HistoryROE[] = result.data as HistoryROE[];

		const dataROE: Array<ROE> = [{
			no: 0,
			date: "",
			percentage: "",
			total: ""
		}];

		let no: number = 1;
		for(const i of data) {
			dataROE.push({
				no: no,
				date: i.created_date,
				percentage: i.roe,
				total: `$ ${i.bonus}`
			});
			no++;
		}

		return dataROE;
	};

	const getHistoryMatching = async () => {
		const response = await fetcher("GET", historyMatchingURL);
		if (response.status === 500) {
			throw Error("Something went wrong, please try again!");
		}

		if (response.status === 401) {
			clearToken(getToken());
			throw Error("Please re-login!");
		}
		const result = await response.json();
		const data: DataHistoryMatching = result.data;
		
		const dataGeneration: MatchingROE[] = [{
			no: 0,
			date: "",
			name: "",
			generation: "",
			"bonus matching": "",
			ROE: ""
		}];
		let i: keyof DataHistoryMatching;
		let no: number = 1;

		for (i in data) {
			for (const j of data[i]) {
				dataGeneration.push({
					no: no,
					date: j.created_date.toString(),
					name: j.user,
					generation: `Generation ${i}`,
					"bonus matching": `${j.bonus_matching}`,
					ROE: `${j.bonus}`
				});
				if (data[i].length !== 0) {
					no++;
				}
			}
		}

		return dataGeneration;
	};

	const getAllData = async () => {
		try {
			const generation = await getGenerationBonus();
			const purchase = await getHistoryPurchase();
			const sponsor = await getHistorySponsor();
			const dataROE = await getHistoryROE();
			const matching = await getHistoryMatching();
			const allData = [
				purchase,
				withdraw,
				dataROE,
				sponsor,
				generation,
				matching
			];
			setAllData(allData);
		} catch (error) {
			setInfoPage({ type: "errorMessage", value: error.message ?? "Something went wrong!" });
			setInfoPage({ type: "hasError", value: true });
		} finally {
			setInfoPage({ type: "hasError", value: false });
			setLoading(false);
		}
	};

	React.useEffect(() => {
		if (allData.length < 6) {
			setLoading(true);
		}
		getAllData();
	}, []);
	
	// Hooks
	const { open, showCollapse } = HistoryHooks();

	return (
		<Container>
			<Loading open={loading}/>
			<Grid container direction="row" spacing={5}>
				{category.map((el: string, index: number) => (
					<Grid item xs={12} key={el}>
						<MyListComponent el={el} open={open[el]} showCollapse={showCollapse} data={allData[index]}/>
					</Grid>
				))}
			</Grid>
		</Container>
	);
}