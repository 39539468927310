import { ActionInfoPage, InfoPage } from "../models/info_page.state";
import { ActionErrorField, ActionUserInput as ActionUserInput, ErrorUserInput, UserInput } from "../models/input.state";
import { ActionMyModal, MyModalInterface } from "../models/modal.model";

export const reducerUserInput = (state: UserInput, action: ActionUserInput) => {
	switch (action.type) {
	case "email":
	case "isHidden":
	case "name":
	case "password":
	case "phone":
	case "refferal_code":
	case "whichActive":
	case "amount purchase":
	case "eth":
	case "doge":
	case "currency":
	case "agree":
	case "address":
	case "isDone":
	case "isSubmit":
	case "isModalOpening":
	case "old password":
	case "codePhone": {
		return { ...state, [action.type]: action.value };
	}
	default:
		return state;
	}
};

export const reducerMyModal = (state: MyModalInterface, action: ActionMyModal) => {
	switch (action.type) {
	case "address":
	case "isLoading":
	case "isOpen":
	case "onClose":
	case "title":
		return { ...state, [action.type]: action.value };
	default:
		return state;
	}
};

export const reducerLoadingSnack = (state: InfoPage, action: ActionInfoPage) => {
	switch (action.type) {
	case "errorMessage":
	case "hasError":
	case "loading":
		return { ...state, [action.type]: action.value };
	default:
		return state;
	}
};

export const reducerErrorField = (state: ErrorUserInput, action: ActionErrorField) => {
	switch (action.type) {
	case "codePhone":
	case "email":
	case "isHidden":
	case "name":
	case "password":
	case "phone":
	case "refferal_code":
	case "whichActive":
	case "amount purchase":
	case "address":
	case "isDone":
	case "isSubmit":
	case "isModalOpening":
	case "old password":
	case "currency":
		return { ...state, [action.type]: action.value };
	default:
		return state;
	}
};